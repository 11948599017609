<template>
  <div>
    <Breadcrumbs title="Invoices" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <div class="m-t-6 col-12 col-md-6">
                  <b-input-group>
                    <b-form-input v-model="filter" placeholder="Type to Search" class="border btn-square" autofocus></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" class="btn btn-square border" variant="primary">Clear</b-button>
                      <b-button v-if="false" @click="showCreateModal" class="btn btn-square border" variant="success">New Invoice</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div class="m-t-10 col-12 col-md-6 text-md-right">
                  <b-form-group label-cols="9" label="Per Page">
                    <b-form-select class="btn-square border text-center" v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="table-responsive datatable-vue">
                <b-table
                    ref="table"
                    show-empty
                    selected-variant="success"
                    :items="tableRecords"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="1"
                    :per-page="perPage"
                    @filtered="onFiltered"
                    @row-selected="rowSelected"
                >
                  <template #cell(student_name)="row" class="text-right">
                    {{ row.item.user.first_name}} {{ row.item.user.last_name}}
                  </template>
                  <template #cell(old_id)="row" >
                    {{ row.item.old_id || "N/A"}}
                  </template>
                  <template #cell(created_at)="row" class="text-right">
                    {{ new Date(row.item.created_at).toDateString() }}
                  </template>
                  <template #cell(status)="row" class="text-center">
                    <strong class="font-success" v-if="row.item.status===0">PAID</strong>
                    <strong class="font-warning" v-if="row.item.status===2">CANCELLED</strong>
                    <strong class="font-mute" v-if="row.item.status===3">REFUND</strong>
                  </template>
                  <template #cell(action)="row" class="text-right">
                    <b-button v-show="checkUserPermissions(user,['resend_invoices'])" class="btn-square" variant="success" @click="showPrintModal(row.index, row.item, $event.target)">Print</b-button>
                    <b-button v-show="checkUserPermissions(user,['view_invoices'])" class="btn-square" variant="info" @click="showNoteModal(row.index, row.item, $event.target)">Notes</b-button>
                    <b-button v-show="checkUserPermissions(user,['resend_invoices'])" class="btn-square" variant="primary" @click="showResendModal(row.index, row.item, $event.target)">Resend</b-button>
                    <b-button v-show="checkUserPermissions(user,['delete_invoices'])" class="btn-square" variant="danger" @click="showDeleteModal(row.index, row.item, $event.target)">Remove</b-button>
                  </template>
                </b-table>
              </div>


              <b-row >
                <b-col cols="4">
                  Records: <strong>{{ totalRows }}</strong>
                </b-col>
                <b-col cols="8" class="my-1" v-if="totalRows">
                  <b-pagination
                      align="right"
                      class="my-0"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      pills
                  ></b-pagination>
                </b-col>
              </b-row>





              <!-- Begin Note Modal -->
              <b-modal id="note_modal" title="Note" cancel-variant="default" ok-variant="danger" ok-title="Confirm" @hide="clearFormModals" @ok="clearFormModals">
                <p v-text="selected.note"></p>
              </b-modal>
              <!-- End Note Modal -->

              <!-- Begin Delete Modal -->
              <b-modal id="delete_modal" title="Delete" cancel-variant="default" ok-variant="danger" ok-title="Confirm" @hide="clearFormModals" @ok="submitDeleteModal">
                <h6 class="m-b-10">Please confirm removal of following record:</h6>
                <strong v-text="selected.name"></strong>
              </b-modal>
              <!-- End Delete Modal -->

              <!-- Begin Create Modal -->
              <b-modal id="form_modal" :title="form_modal_title" cancel-variant="default" ok-variant="primary" :ok-title="form_modal_ok_title" @hide="clearFormModals" @ok="submitFormModal">

                <b-form-group v-if="selected.id" label="Id">
                  <b-input v-if="form_modal_id === 'form_modal_create'" v-model="selected.id"></b-input>
                  <b-input v-else v-model="selected.id" readonly></b-input>
                </b-form-group>

                <b-form-group label="Name">
                  <b-input v-model="selected.name"></b-input>
                </b-form-group>

              </b-modal>
              <!-- End Create Modal -->

              <!-- Begin Create Modal -->
              <b-modal id="resend_invoice_modal" title="Resend Invoice" cancel-variant="default" ok-variant="primary" ok-title="Resend" @hide="clearFormModals" @ok="submitResendInvoiceModal">

                <b-form-group label="Invoice ID">
                  <b-input v-model="resend_invoice.invoice_id" readonly></b-input>
                </b-form-group>

                <div class="animate-chk">
                  <b-form-group label="Send Via *">
                    <div class="row">
                      <div class="checkbox-animated col-6 col-md-3">
                        <label class="d-block">
                          <input class="checkbox_animated" name="permissions" type="checkbox"
                                 v-model="resend_invoice.send_email"
                                 :checked="resend_invoice.send_email"
                                 value="1"
                          > E-Mail
                        </label>
                      </div>
                      <div class="checkbox-animated col-6 col-md-3">
                        <label class="d-block">
                          <input class="checkbox_animated" name="permissions" type="checkbox"
                                 v-model="resend_invoice.send_sms"
                                 :checked="resend_invoice.send_sms"
                                 value="1"
                          > SMS
                        </label>
                      </div>
                    </div>
                  </b-form-group>
                </div>

                <span class="text-muted">* - Sent only when student email is available</span>

              </b-modal>
              <!-- End Create Modal -->

              <div style="z-index: -8" id="printMe" v-html="printMe"></div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {checkUserPermissions} from '@/plugins/functions'
import { mapState } from 'vuex'
import CRUD_API from '@/services/crud'

export default {
  name: "invoices",
  data() {
    return {
      filter: '',
      totalRows: 0,
      perPage: 5,
      currentPage: 1,
      pageOptions: [5,10,25,50,100],
      tableRecords: [],
      selected: [],

      api_base: '/api/backend/pages/invoices',
      tableFields: [
        { key: 'id', label: 'ID', sortable: false },
        { key: 'old_id', label: 'Old ID', sortable: false },
        { key: 'created_at', label: 'Date / Time', sortable: false },
        { key: 'creator.username', label: 'Created By', sortable: false },
        { key: 'student_name', label: 'Student', sortable: false },
        { key: 'gross_amount', label: 'Gross Amount', sortable: false, class: 'text-right' },
        { key: 'discount', label: 'Discounts', sortable: false, class: 'text-right' },
        { key: 'net_amount', label: 'Net Amount', sortable: false, class: 'text-right' },
        { key: 'status', label: 'Status', sortable: false, class:'text-center' },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],


      // Form Modal
      form_modal_id: '',
      form_modal_title: '',
      form_modal_ok_variant: 'primary',
      form_modal_ok_title: '',

      // Resend Invoice Modal
      resend_invoice: {
        invoice_id: null,
        send_email: 1,
        send_sms: 0
      },
      printMe: '',
    }
  },
  watch: {
    currentPage() {
      this.fetchData()
    },
    perPage() {
      this.currentPage = 1
      this.fetchData()
    },
    filter() {
      this.currentPage = 1
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  computed: mapState({ user: state => JSON.parse(state.auth.user) }),
  methods: {
    checkUserPermissions,
    onFiltered(filteredItems) {
      this.currentPage = 1
      this.items.count = filteredItems.length
    },
    rowSelected(item) {
      this.selected = item
    },
    clearSearch() {
      this.filter = ''
      this.currentPage = 1
      this.fetchData()
    },
    handlePageChange(value) {
      //alert(value)
      this.perPage = value
      this.fetchData()
    },
    handlePageSizeChange(value) {
      this.currentPage = value
      this.fetchData()
    },
    async fetchData() {
      await CRUD_API.index( this.api_base, {
        url: this.api_base,
        params: {
          per_page: this.perPage,
          page: this.currentPage,
          filter: this.filter
        }
      }).then(function(resp) {
        this.tableRecords = []
        this.totalRows = resp.data.data.total
        this.tableRecords = resp.data.data.data
      }.bind(this)).catch((err) => {
        console.log(err)
        this.$toasted.error("Error occurred while retrieving data")
      }).finally(() => {
        this.$refs.table.refresh()
      })
    },

    clearFormModals() { this.selected = []; },
    // Create Record Modal
    showCreateModal(button) {
      this.selected = { id: null, name: null, status: null }
      this.form_modal_title = "New Invoice "
      this.form_modal_ok_title = 'Submit'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitFormModal() {
      if (this.selected.id) {
        this.submitEditModal()
      } else {
        this.submitCreateModal()
      }
    },
    submitCreateModal() {
      CRUD_API.create(this.api_base, this.selected)
          .then((resp) => {
            console.log(resp)
            this.$toasted.success("Invoice added successfully")
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while saving data")
          })
          .finally(() => {
            this.fetchData()
          })
    },
    // Edit Record Modal
    showEditModal(index, item, button) {
      this.selected = item
      this.form_modal_title = "Edit Invoice "
      this.form_modal_ok_title = 'Update'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitEditModal(){
      CRUD_API.update(this.api_base, this.selected.id, this.selected)
          .then((resp) => {
            console.log(resp)
            this.$toasted.success("Invoice updated successfully")
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while updating data")
          })
          .finally(() => {
            this.fetchData()
          })
    },
    // Delete Record Modal
    showDeleteModal(index, item, button) {
      this.selected = item
      this.$root.$emit('bv::show::modal', 'delete_modal', button)
    },
    submitDeleteModal(){
      CRUD_API.delete(this.api_base, this.selected.id)
          .then((resp) => {
            console.log(resp)
            this.$toasted.success("Invoice removed successfully")
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while removing data")
          })
          .finally(() => {
            this.fetchData()
          })
    },

    showResendModal(index, item, button) {
      this.resend_invoice.invoice_id = item.id;
      this.resend_invoice.send_email = 1;
      this.resend_invoice.send_sms = 0;
      this.$root.$emit('bv::show::modal', 'resend_invoice_modal', button)
    },
    submitResendInvoiceModal() {
      CRUD_API.create(this.api_base + '/resend', this.resend_invoice)
          .then(() => {
            this.$toasted.success("Invoice resent successfully")
          })
          .catch(err => {
            console.log(err)
            this.$toasted.error("Error occurred while resending invoice!")
          })
    },

    showNoteModal(index, item, button) {
      this.selected = item
      this.$root.$emit('bv::show::modal', 'note_modal', button)
    },

    showPrintModal(index, item) {
      let windowTitle = "Invoice - " + item.id + '';
      CRUD_API.create('/api/backend/print-invoice', item)
          .then(resp => {this.printMe = resp.data.view;}).then(()=>this.$htmlToPaper('printMe', { windowTitle })).then(() => {this.printMe = '';})
    },
  }
}
</script>

<style scoped>

</style>
